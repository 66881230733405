<template>
    <div class="wrap-list-platform">
        <CRow>
            <CCol class="col--padding">
                <CCard>
                    <CCardBody>
                        <label class="title-items">Token Payout</label>
                        <table class="table table-striped table-drag">
                            <thead class="thead-white">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{{$t('PAYOUT_PAGE_TABLE_COL_STATUS')}}</th>
                                <th scope="col">{{$t('PAYOUT_PAGE_TABLE_COL_PLATFORM')}}</th>
                                <th scope="col">{{$t('PAYOUT_PAGE_TABLE_COL_TOKEN_NAME')}}</th>
                                <th scope="col">{{$t('PAYOUT_PAGE_TABLE_COL_TOKEN_CODE')}}</th>
                                <th scope="col">{{$t('PAYOUT_PAGE_TABLE_COL_ADDRESS')}}</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <Draggable v-model="platforms" v-bind="dragOptions" tag="tbody">
                                <tr v-for="(item, index) in platforms" :key="item.id" class="t-body">
                                    <td scope="row">{{ index + 1+(activePage-1)*limit }}</td>
                                    <td></td>
                                    <td>{{item.platform}}</td>
                                    <td>{{item.token_name}}</td>
                                    <td>{{item.token_symbol}}</td>
                                    <td>{{item.token_address}}</td>
                                    <td></td>
                                </tr>
                            </Draggable>
                        </table>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
import Draggable from 'vuedraggable';
import endpoints from '@/constants/endpoints';
import {mapActions} from 'vuex';
import responseHelper from '@/utils/response-helper';
export default {
    components: {
        Draggable
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            };
        },
    },
    data() {
        return {

            platforms: [],
            limit: 5,
            activePage:1,
            pageInput:1,
            total:null,

        };
    },
    async mounted() {
        var data = await this.fetchPayoutList();
        this.platforms = data.items;
        this.total = data.total;
    },
    methods:{
        async fetchPayoutList(){
            try {
                const params = {
                    limit: this.limit,
                    offset: this.offset
                };
                var result = await this.$http.get(
                    endpoints.getPayout,
                    { params }
                );
                if (result.data) {
                    return result.data;
                }
            } catch (err) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: this.$t('LIST_PAYOUT_PAGES_TITLE_NOTIFY_ERROR'),
                    text: responseHelper.getErrorMessage(err)
                });
            }
        },

    }
};
</script>
<style lang="scss" scoped>
    .col--padding{
        padding: 0 24px;
    }
    .row {
        padding: 0;
        border: 0;
        margin: 0;
    }
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    .list-group {
        min-height: 20px;
    }
    .list-group-item {
        cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
    .table-drag {
        border: 1px solid #d8dbe0;
    }
    .t-body {
        cursor: grab;
    }
    .title-items{
        color: #657187;
        // font-family: "SF UI Display";
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }
    .btn-link{
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.5px;
        color: #6B61E8;
    }
    .btn-link-pages{
        display: inline-block;
        float: right;
    }
    .img-icon{
        width: 20px;
        height: auto;
        margin-right: 10px;
    }
</style>